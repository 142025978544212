import { Button, Checkbox } from '@mui/material'
import theme from '@theme'
import { UserPrefKey } from '@type/usePref'
import { getFilters, setFilters } from '@util/helpers'

export const PublishedSwitch = ({
  prefKey,
  tableId,
}: {
  prefKey: UserPrefKey
  tableId?: string
}) => {
  const publishedFilter = getFilters('published', tableId)

  const onCLickHandler = (value) =>
    publishedFilter?.includes(value)
      ? setFilters({
          filterKey: 'published',
          value: publishedFilter ? publishedFilter.filter((x) => x !== value) : [],
          prefKey,
          tableId,
        })
      : setFilters({
          filterKey: 'published',
          value: [...(publishedFilter || []), value],
          prefKey,
          tableId,
        })

  return (
    <>
      <Button
        style={{
          padding: '5px',
          borderRadius: '4px',
          border: `1px solid ${theme.palette.greyTable}`,
          color: 'black',
        }}
        onClick={() => onCLickHandler('yes')}
      >
        <Checkbox
          size="small"
          sx={{
            borderRadius: 4,
            width: 16,
            height: 16,
            paddingRight: 2,
            '& .MuiSvgIcon-root': {
              color: 'black',
            },
          }}
          disabled
          checked={!!publishedFilter?.includes('yes')}
        />
        Published
      </Button>
      <Button
        style={{
          padding: '5px',
          borderRadius: '4px',
          border: `1px solid ${theme.palette.greyTable}`,
          color: 'black',
        }}
        onClick={() => onCLickHandler('no')}
      >
        <Checkbox
          size="small"
          sx={{
            borderRadius: 4,
            width: 16,
            height: 16,
            paddingRight: 2,
            '& .MuiSvgIcon-root': {
              color: 'black',
            },
          }}
          disabled
          checked={!!publishedFilter?.includes('no')}
        />
        UnPublished
      </Button>
    </>
  )
}
