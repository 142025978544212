import { AddResponse, DeleteUpdateResponse, QueryParams } from '@type/common'

import { Debate, DebateCreateDTO, Publish } from '@type/debate'
import { getDebateDateFormat } from '@util/helpers'
import { getAxios } from './api'

const debateDayApiPath = '/debate-days'
export const getAllDebates = async () => {
  const { data } = await getAxios().get(debateDayApiPath)
  return data
}

const getCurrentPublishedApiPath = '/debate-days/current-published'
export const getCurrentPublished = async () => {
  const { data } = await getAxios().get(getCurrentPublishedApiPath)
  return data
}

export const getAllFormattedDebates = async (params: QueryParams) => {
  const { data } = await getAxios().get(debateDayApiPath, {
    params,
  })
  const formattedData: Debate[] = data?.data?.map((debate: Debate) => ({
    ...debate,
    date: getDebateDateFormat(debate.activityDay),
    public: debate.isPublic ? 'Yes' : 'No',
  }))
  return { ...data, data: formattedData }
}

export const getDebate = async (id: string) => {
  if (!id) {
    return null
  }
  const { data } = await getAxios().get<DebateCreateDTO>(`${debateDayApiPath}/${id}`)
  return data
}

export const addDebate = async (values: DebateCreateDTO) => {
  const { data } = await getAxios().post<AddResponse<DebateCreateDTO>>(debateDayApiPath, values)
  return data
}

export const editDebate = async (debate: Debate) => {
  const { data } = await getAxios().put<DeleteUpdateResponse>(
    `${debateDayApiPath}/${debate?.id}`,
    debate,
  )
  return data
}

export const deleteDebate = async (id: string) => {
  const { data } = await getAxios().delete<DeleteUpdateResponse>(`${debateDayApiPath}/${id}`)
  return data
}

export const updateDebatePublishStatus = async (publish: Publish) => {
  const { id, isPublic } = publish
  const { data } = await getAxios().put<DeleteUpdateResponse>(`${debateDayApiPath}/publish/${id}`, {
    isPublic,
  })
  return data
}
