/* eslint-disable lines-around-comment */
import { FilterList } from '@mui/icons-material'
import {
  Badge,
  Box,
  Button,
  InputBase,
  Popover,
  Stack,
  styled,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import {
  MRT_DensityState,
  MRT_GlobalFilterTextField,
  MRT_Row,
  MRT_ShowHideColumnsButton,
  MRT_Table,
  MRT_TableContainer,
  MRT_TableOptions,
  MRT_TablePagination,
  MRT_ToggleDensePaddingButton,
  MRT_ToolbarInternalButtons,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_RowData,
} from 'material-react-table'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { ReactNode, useEffect, useState } from 'react'
// @ts-ignore
import { download, generateCsv, mkConfig } from 'export-to-csv'
import qs from 'qs'
import { DEFAULT_PAGINATION_LIMIT } from '@constant'
import theme from '@theme'
import { useMTPageParams } from './hooks/usePageParams'
import { useTableParams } from './hooks/useTableParams'

interface TableState {
  pagination: {
    page: number
    limit: number
  }

  sorting: {
    column: string
    arrange: 'asc' | 'desc'
  }[]

  search: string
}

/**
 * Props interface for a table component.
 * @template T - The type of data items in the table.
 */
interface TableProps<T extends MRT_RowData> {
  /** Unique identifier for the table. */
  tableId?: string

  /** Array of data items to be displayed in the table. */
  data: T[]

  /** Array of column definitions specifying how to render each column in the table. */
  columns: MRT_ColumnDef<T>[]

  /** Whether to enable row numbers in the table. Defaults to false. */
  enableRowNumbers?: boolean

  /** Whether to enable row selection in the table. Defaults to false. */
  enableRowSelection?: boolean

  /** Enable CSV Export */
  enableCSVExport?: boolean

  /** Callback function triggered when a row is dragged. */
  onRowDrag?: (srcIndex: number, destIndex: number) => void

  /** Callback function triggered when a row is clicked. */
  onRowClick?: (row: T) => void

  /** Callback function triggered when a row is hovered. */
  onRowHover?: (row: T) => void

  /** Callback function triggered when refetch button is clicked. */
  additionalToolbarButtons?: React.ReactNode

  /** Callback function to get row link */
  getRowLinkPath?: (row: T) => string | undefined

  /** Function that returns a React component for additional actions associated with each row. */
  getRowActionsComponent?: (row: T) => React.ReactNode

  /** Function that returns a React components for menu actions associated with each row. */
  getRowActionsItems?: (row: T) => React.ReactNode[]

  /** Height of the table. */
  height?: string

  /** Current state of the table. */
  tableState?: TableState

  /** Callback function triggered when the table state changes. */
  onTableStateChange?: (newState: TableState) => void

  /** Whether to automatically manage certain features of the table. Defaults to false. */
  auto?: boolean

  /** Total number of rows in the dataset, useful for pagination. */
  rowCount?: number

  /** Whether the table is in a loading state. Defaults to false. */
  loading?: boolean

  /** Whether to hide the pagination UI. Defaults to false. */
  hidePagination?: boolean

  /** Whether to hide the toolbar UI. Defaults to false. */
  hideToolbar?: boolean

  /** Get Row Id */
  getRowId?: (row: T) => string

  /** Pagination Type */
  paginationType?: 'offset' | 'cursor'

  /** Cursors for PaginationType = "cursor" */
  cursors?: {
    startCursor?: string
    endCursor?: string
  }

  /** Filters Component for table */
  filters?: Record<string, React.ReactNode>

  onRowSelect?: (selected: string[]) => void

  /** Default Page Size */
  defaultPageSize?: number

  /** Custom CSS styles for the table header cells. */
  tableStyle?: {
    head?: React.CSSProperties
    // Add other style properties as needed
  }
  tableOptions?: Partial<MRT_TableOptions<T>>

  /** Column Alignment */
  columnAlignment?: { [key: string]: 'left' | 'center' | 'right' }

  /** Column Visibility with row id as key and visibility as value */
  columnVisibility?: Record<string, boolean>

  /** Custom Row Style Function */
  getRowStyle?: (row: MRT_Row<T>) => SxProps<Theme>
  /***/
  headerRightContent?: ReactNode
}

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
})

export const MUITable = <T extends MRT_RowData>({
  tableId,
  data = [],
  columns,
  enableRowNumbers,
  enableRowSelection,
  enableCSVExport,
  onRowClick,
  onRowHover,
  getRowActionsComponent,
  getRowActionsItems,
  getRowLinkPath,
  height,
  tableState,
  onTableStateChange,
  auto,
  hidePagination = false,
  hideToolbar = false,
  rowCount,
  loading,
  getRowId,
  paginationType = 'offset',
  cursors,
  filters,
  onRowSelect,
  additionalToolbarButtons,
  onRowDrag,
  defaultPageSize = DEFAULT_PAGINATION_LIMIT,
  tableStyle,
  tableOptions,
  columnAlignment,
  columnVisibility,
  getRowStyle,
  headerRightContent,
}: TableProps<T>) => {
  const router = useRouter()

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data)
    download(csvConfig)(csv)
  }

  const {
    globalFilter,
    onGlobalFilterChange,
    onPaginationChange,
    onSortingChange,
    pagination,
    sorting,
  } = useTableParams({
    tableId,
    tableState,
    onTableStateChange,
    paginationType,
    cursors,
    defaultPageSize,
  })

  const { filters: appliedFilters } = useMTPageParams({ tableId })

  const [rowSelection, setRowSelection] = React.useState({})
  const [density, setDensity] = useState<MRT_DensityState>('compact')

  useEffect(() => {
    if (data && data.length !== 0) {
      onRowSelect?.(Object.keys(rowSelection))
    }
    // removed dependencies due to infinite re renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection])

  useEffect(() => {
    setRowSelection([])
  }, [data])

  const states = auto
    ? {
        state: {
          isLoading: loading,
          density,
        },
      }
    : {
        onGlobalFilterChange,
        onPaginationChange,
        onSortingChange,
        state: {
          pagination,
          sorting,
          density,
          globalFilter,
          isLoading: loading,
          rowSelection,
        },
      }

  const table = useMaterialReactTable({
    data,
    columns,
    manualPagination: !auto,
    enableRowOrdering: !!onRowDrag,
    manualSorting: !auto,
    enableStickyHeader: true,
    enableColumnActions: false,
    enableColumnOrdering: true,
    enableRowActions: !!getRowActionsComponent || !!getRowActionsItems,
    enableColumnResizing: true,
    enableRowNumbers,
    enableRowSelection,
    manualFiltering: !auto,
    enableGlobalFilter: auto,
    onRowSelectionChange: (e) => {
      onRowSelect?.([...Object.keys(e), ...Object.keys(rowSelection)])
      setRowSelection(e)
    },
    muiRowDragHandleProps: ({ table: tbl }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = tbl.getState()
        if (hoveredRow && draggingRow) {
          onRowDrag?.(draggingRow.index, (hoveredRow as MRT_Row<T>).index)
        }
      },
    }),

    rowCount: auto ? data.length : Number(rowCount) || 0,

    ...states,

    muiTableContainerProps: {
      sx: {
        maxHeight: height || '100%',
        background: 'white',
        border: `1px solid ${theme.palette.grey5}`,
        borderRadius: '6px',
      },
    },
    enableFilterMatchHighlighting: false,
    muiTablePaperProps: {
      sx: { background: 'white' },
    },
    muiTableHeadRowProps: {
      sx: { boxShadow: 'none', background: 'rgb(245, 245, 245)', ...(tableStyle?.head || {}) },
    },
    muiTopToolbarProps: {
      sx: { background: 'white', p: 0, height: '100%' },
    },
    muiTableHeadCellProps: (row) => ({
      title: row.column.columnDef.header,
      sx: {
        textTransform: 'uppercase',
      },
    }),
    muiTableBodyRowProps: ({ row }) => ({
      component: getRowLinkPath?.({ field: row.id, ...row.original }) ? Link : 'tr',
      href: getRowLinkPath?.({ field: row.id, ...row.original }),
      onClick: () => {
        onRowClick?.({ field: row.id, ...row.original })
      },
      onMouseEnter: () => {
        onRowHover?.({ ...row.original })
      },
      sx: {
        cursor: onRowClick || getRowLinkPath ? 'pointer' : 'default',
        ':last-child': {
          td: {
            borderBottom: 0,
          },
        },
        background: 'white !important',
        ...(getRowStyle?.(row) || {}),
      },
    }),
    onDensityChange: setDensity,
    muiTableBodyCellProps: (row) => ({
      sx: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: '4ch',
        display: row.column.id === 'mrt-row-numbers' ? 'flex' : 'block',
        ':hover': {
          textOverflow: 'ellipsis',
        },
        padding: `${
          // eslint-disable-next-line no-nested-ternary
          density === 'compact' ? '0.3rem' : density === 'comfortable' ? '0.5rem' : '0.7rem'
        } 0.7rem`,
        alignContent: 'center',
        ...(columnAlignment?.[row.column.id] && { textAlign: columnAlignment[row.column.id] }),
      },
    }),
    muiPaginationProps: {
      shape: 'rounded',
      sx: { fontSize: '12px' },
    },
    muiSearchTextFieldProps: {
      placeholder: 'Search...',
      sx: { minWidth: '100%', m: 0 },
      variant: 'outlined',
    },
    paginationDisplayMode: 'default',
    positionGlobalFilter: 'left',
    positionActionsColumn: 'last',

    renderRowActions: getRowActionsComponent
      ? ({ row: { original } }) => getRowActionsComponent?.(original)
      : undefined,
    renderRowActionMenuItems: getRowActionsItems
      ? ({ row: { original } }) => getRowActionsItems?.(original)
      : undefined,

    defaultColumn: {
      grow: true,
    },

    layoutMode: 'grid',

    muiSkeletonProps: {
      animation: 'wave',
    },

    getRowId,

    renderToolbarInternalActions: ({ table: tbl }) => (
      <Stack direction="row" gap="8px">
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <MRT_ShowHideColumnsButton
          sx={{
            borderRadius: '4px',
            color: '#3f3f46',
            border: `1px solid ${theme.palette.greyTable}`,
          }}
          size="small"
          table={tbl}
        />

        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <MRT_ToggleDensePaddingButton
          sx={{
            borderRadius: '4px',
            color: '#3f3f46',
            border: `1px solid ${theme.palette.greyTable}`,
          }}
          size="small"
          table={tbl}
        />
      </Stack>
    ),

    displayColumnDefOptions: {
      'mrt-row-actions': {
        grow: 0,
        header: '',
      },
    },

    initialState: {
      showGlobalFilter: true,
      density: 'compact',
      globalFilter,
      pagination: {
        pageIndex: 0,
        pageSize: defaultPageSize,
      },
      columnVisibility,
    },
    ...(tableOptions || {}),
  })

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const customFiltersCount = appliedFilters?.orConditions
    ?.flatMap((or) => or?.andConditions)
    ?.filter((v) => Object.keys(filters || {}).includes(v?.column))?.length

  return (
    <Stack gap="8px">
      {!hideToolbar && (
        <Box display="flex" alignItems="center" justifyContent="space-between" gap="10px">
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <MRT_GlobalFilterTextField table={table} />
          <Box display="flex" alignItems="center" gap="8px">
            {additionalToolbarButtons}
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <MRT_ToolbarInternalButtons table={table} />
            {enableCSVExport && (
              <Button variant="outlined" color="inherit" onClick={handleExportData}>
                Export CSV
              </Button>
            )}
            {filters && (
              <Badge
                color="success"
                badgeContent={customFiltersCount}
                invisible={!customFiltersCount}
              >
                <Button
                  variant="outlined"
                  color="inherit"
                  aria-describedby={id}
                  onClick={handleClick}
                >
                  <FilterList sx={{ mr: '4px' }} /> Filters
                </Button>
              </Badge>
            )}

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Box width="360px" display="flex" flexDirection="column">
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: `1px solid ${theme.palette.neutralVariant90}`,
                    p: '12px',
                  }}
                >
                  <Typography variant="h4">Select Filters</Typography>
                  <Button
                    color="error"
                    onClick={() => {
                      delete router.query.filters
                      router.push(
                        {
                          query: {
                            ...router.query,
                            pagination: qs.stringify({
                              page: 1,
                              after: '',
                              limit: pagination.pageSize,
                            }),
                          },
                        },
                        undefined,
                        {
                          shallow: true,
                        },
                      )
                      setAnchorEl(null)
                    }}
                  >
                    Reset
                  </Button>
                </Box>

                <Box display="flex" flexDirection="column" gap="16px" px="12px" py="8px">
                  {Object.values(filters || {})?.map((t) => t)}
                </Box>
              </Box>
            </Popover>
            {headerRightContent}
          </Box>
        </Box>
      )}

      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <MRT_TableContainer table={table}>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <MRT_Table table={table} />
      </MRT_TableContainer>

      {!hidePagination && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box />
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <MRT_TablePagination
            SelectProps={{
              size: 'small',
              variant: 'outlined',
              input: <BootstrapInput />,
            }}
            siblingCount={0}
            boundaryCount={0}
            table={table}
            rowsPerPageOptions={[5, 10, 50, 100, DEFAULT_PAGINATION_LIMIT]}
            variant="outlined"
          />
        </Box>
      )}
    </Stack>
  )
}

const BootstrapInput = styled(InputBase)(({ theme: thm }) => ({
  'label + &': {},
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: thm.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 12,
    padding: '5px',
    transition: thm.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))

export { MRT_ColumnDef as Column }
