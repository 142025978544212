import { Column } from '@tanstack/react-table'
import get from 'lodash/get'
import { useState } from 'react'
import { useQuery } from 'react-query'

import { SxProps } from '@mui/material'
import TableGridContainer from '@element/TableGridContainer/TableGridContainer'
import PaginatedTable, { ActionMenu } from '@module/Table/PaginatedTable'
import { CompositeKeyValue, QueryParams } from '@type/common'
import useDebounce from '@hook/common/useDebounce'

type Props = {
  apiCall: (query: QueryParams) => void
  columns: Column<object>[]
  additionalParams?: CompositeKeyValue
  dataLabel?: string
  queryKey: string
  title?: string
  rowClickLink?: string
  containerSx?: SxProps
  noDataText?: string
  initialData?: []
  hasActionCol?: boolean
  actionMenus?: ActionMenu[]
  renderDynamicActionMenu?: Function
  showSearch?: boolean
}

const List = ({
  apiCall,
  columns = [],
  additionalParams = {},
  dataLabel = 'data',
  queryKey,
  title,
  rowClickLink = '',
  containerSx = {},
  noDataText = 'No Quests available.',
  initialData = [],
  hasActionCol = false,
  actionMenus = [],
  renderDynamicActionMenu,
  showSearch = false,
}: Props) => {
  const [data, setData] = useState<any>(initialData)
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(100)
  const [searchParams, setSearchParams] = useState<string>('')
  const debouncedSearch = useDebounce(searchParams, 500)

  const { isLoading, isFetching, refetch } = useQuery(
    [queryKey, page, rowsPerPage, debouncedSearch, JSON.stringify(additionalParams)],
    () =>
      apiCall({
        page: page + 1,
        limit: rowsPerPage > 0 ? rowsPerPage : count,
        search: debouncedSearch,
        ...additionalParams,
      }),
    {
      refetchOnMount: true,
      onSuccess(response: any) {
        setData(get(response, dataLabel, []))
        setCount(get(response, 'count', 0))
      },
    },
  )

  const handleSearchParamChange = (value) => {
    setCount(0)
    setPage(0)
    setSearchParams(value)
  }

  return (
    <TableGridContainer sx={{ pl: '15px', flex: 1 }}>
      <PaginatedTable
        columns={columns}
        containerSx={containerSx}
        data={data}
        count={count}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setSideBarData={() => {}}
        noDataText={noDataText}
        showNoDataButton={false}
        isLoading={isLoading || isFetching}
        title={title}
        setSearchParams={showSearch ? handleSearchParamChange : null}
        rowClickLink={rowClickLink}
        hasActionCol={hasActionCol}
        actionMenus={actionMenus}
        renderDynamicActionMenu={(row) => {
          if (renderDynamicActionMenu) return renderDynamicActionMenu(row, refetch)
          return null
        }}
      />
    </TableGridContainer>
  )
}

export default List
