import { Box, CircularProgress } from '@mui/material'
import useCurrentUser from '@hook/user/useCurrentUser'
import DebateNow from './debate/debate-now'
import PhilosophicPackInternetApps from './philosophy-pack'

export default function RiddedHqHome() {
  const { isAdmin, isLoading } = useCurrentUser()
  if (isLoading) {
    return (
      <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return isAdmin ? <DebateNow /> : <PhilosophicPackInternetApps />
}
