import { useRouter } from 'next/router'
import qs from 'qs'
import { DEFAULT_PAGINATION_LIMIT } from '@constant/index'
import { RemoveInvalidFields } from '@util/helpers'
import { ListParams } from '@type/common'

type URLFilterValue = string | string[]

export type URLFilter = Record<
  string,
  {
    value: URLFilterValue
    comparator: 'EqualTo' | 'In'
  }
>

function combinations<T>(...arrays: T[][]): T[][] {
  return arrays.reduce(
    (acc, arr) => acc.flatMap((combination) => arr.map((element) => combination.concat(element))),
    [[]] as T[][],
  )
}

export const mapFilterQuery = (filters: URLFilter) => {
  const tempFilters = Object.keys(filters).map((column) => {
    const value = Array.isArray(filters[column].value)
      ? (filters[column].value as string[]).join(',')
      : (filters[column].value as string)

    const { comparator } = filters[column]

    return [{ value, column, comparator }]
  })

  if (tempFilters.length === 0) {
    return {
      orConditions: [],
    }
  }

  const combinationsArray = combinations(...tempFilters)

  return {
    orConditions: combinationsArray.map((arr) => ({
      andConditions: arr,
    })),
  }
}

type UsePageParams = {
  tableId?: string
  defaultPageSize?: number
}

export const useMTPageParams = ({ tableId, defaultPageSize }: UsePageParams = {}): ListParams => {
  const router = useRouter()
  const prefix = tableId ? `${tableId}_` : ''

  const search = router.query[`${prefix}search`] as string
  const filterQuery = router.query[`${prefix}filters`] as string

  const paginationParams = qs.parse(router.query[`${prefix}pagination`] as string) as unknown as {
    limit: number
    page: number
    after?: string
    before?: string
  }

  const sortingParams = Object.values(
    qs.parse(router.query[`${prefix}sorting`] as string, {
      allowDots: true,
      parseArrays: true,
      comma: true,
    }),
  ) as unknown as {
    column: string
    arrange: string
  }[]

  const parsedQuery = mapFilterQuery({
    ...(qs.parse(filterQuery as string, {
      allowDots: true,
      parseArrays: true,
      comma: true,
    }) as URLFilter),
  })

  const params = {
    pagination: {
      limit: Number(paginationParams.limit) || defaultPageSize || DEFAULT_PAGINATION_LIMIT,
      page: paginationParams.page || 1,
      after: paginationParams.after || undefined,
      before: paginationParams.before || undefined,
    },
    filters: {
      ...parsedQuery,
    },
    search,
    sorting: sortingParams?.length
      ? sortingParams?.map((sorting) => ({
          column: sorting.column,
          arrange: sorting.arrange.toUpperCase(),
        }))?.[0]
      : undefined,
  }
  RemoveInvalidFields(params)

  return params as ListParams
}
