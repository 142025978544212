import { Box, styled } from '@mui/material'
import theme from '@theme'

const ValueStatus = ({ value = '', children }: { value?: string; children?: React.ReactNode }) => {
  const statusColors = {
    inprogress: theme.palette.orange1,
    completed: theme.palette.green2,
    failed: theme.palette.red,
    accepted: theme.palette.green2,
    rejected: theme.palette.red,
  }
  const statusColor = statusColors[value.toLowerCase()] || theme.palette.orange

  return (
    <Wrapper sx={{ background: statusColor }}>
      {value}
      {children ?? null}
    </Wrapper>
  )
}

export default ValueStatus

const Wrapper = styled(Box)`
  display: inline;
  padding: 5px 10px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  color: ${theme.palette.white1};
  text-transform: uppercase;
  border-radius: 15px;
`
