import { Box, Button } from '@mui/material'
import { useRouter } from 'next/router'
import { CellProps } from 'react-table'
import { useMemo } from 'react'
import { getFormattedForumPostByDebateId } from '@api/forum-post.api'
import ROUTES from '@constant/Routes'
import Header from '@module/Header/Header'
import { QueryParams } from '@type/common'
import { SLTableCell } from '@commons/modules/Table/components/TableHelpers'
import { PostForum } from '@type/post-forum'
import { QUEST_URL } from '@constant/index'
import List from '../../commons/List'

/* eslint-disable react/prop-types */
const DebatePublished: React.FunctionComponent<any> = ({ param }) => {
  const router = useRouter()
  const columns = useMemo(
    () => [
      {
        Header: 'Forum Name',
        accessor: 'discussionForum.name',
        Cell: ({ value }: CellProps<PostForum, string>) => (
          <SLTableCell sx={{ pl: 1 }}>{value}</SLTableCell>
        ),
      },
      {
        Header: 'Forum Title',
        accessor: 'title',
        Cell: ({ value }: CellProps<PostForum, string>) => (
          <SLTableCell sx={{ pl: 1 }}>{value}</SLTableCell>
        ),
      },
      {
        Header: 'Platform',
        accessor: 'discussionForum.platform.title',
        Cell: ({ value }: CellProps<PostForum, string>) => (
          <SLTableCell sx={{ pl: 1 }}>{value}</SLTableCell>
        ),
      },
      {
        Header: 'Censored',
        accessor: 'isCensored',
        Cell: ({ value }: CellProps<PostForum, string>) => (
          <SLTableCell sx={{ pl: 1 }}>{value ? 'Yes' : 'No'}</SLTableCell>
        ),
      },
    ],
    [],
  )
  const getForumPostByDebate = (params: QueryParams) =>
    getFormattedForumPostByDebateId(params, param.id)

  return (
    <Box>
      {/* Main Header */}
      <Box sx={{ ml: '10px' }}>
        <Header title={param.title} />
      </Box>
      <Box
        sx={{ ml: '10px' }}
        display="flex"
        alignItems="center"
        justifyContent="left"
        marginTop={0}
      >
        {/* <Box>
          <Button variant="text" size="small">
            Send us Feedback
          </Button>
        </Box> */}
        {/* <Box>
          <Button variant="text" size="small">
            Discord
          </Button>
        </Box> */}
        <Box>
          <Button
            variant="text"
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              router.push(ROUTES.InternetPriorDebates)
            }}
          >
            Prior Debates
          </Button>
        </Box>
        {/* <Box>
          <Button variant="text" size="small">
            Vincent
          </Button>
        </Box> */}
      </Box>
      <Box
        sx={{ ml: '40px' }}
        display="flex"
        alignItems="center"
        justifyContent="left"
        marginTop={0}
      >
        <Button
          sx={{ ml: 1 }}
          variant="outlined"
          size="small"
          onClick={(e) => {
            e.stopPropagation()
            router.push(`${QUEST_URL}${param?.quest?.slug}`)
          }}
        >
          Play This Quest
        </Button>
        <Button
          sx={{ ml: 1 }}
          variant="outlined"
          size="small"
          onClick={(e) => {
            e.stopPropagation()
            router.push(`${QUEST_URL}${param?.quest?.slug}`)
          }}
        >
          Play Now
        </Button>
        <Button
          sx={{ ml: 1 }}
          variant="outlined"
          size="small"
          onClick={(e) => {
            e.stopPropagation()
            router.push({
              pathname: ROUTES.InternetAppsArticle,
              query: { debate: param?.article.sourceUrl },
            })
          }}
        >
          Our Article
        </Button>
      </Box>

      <List
        containerSx={{ mt: 4, pl: '20px', pr: '20px' }}
        columns={columns}
        apiCall={getForumPostByDebate}
        title="List of Discussion forums for this Debate Day"
        rowClickLink={`${ROUTES.InternetEditForumPost}?debate=${param.id}`}
        noDataText="No Forums Available."
        dataLabel="data"
        queryKey="meme-list"
        hasActionCol={false}
      />
    </Box>
  )
}

export default DebatePublished
