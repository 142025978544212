/* eslint-disable react/jsx-props-no-spreading */
import { Grid, GridProps } from '@mui/material'
import { FC } from 'react'

const TableGridContainer: FC<GridProps> = ({ children, ...props }) => (
  <Grid container columnSpacing={2} alignItems="stretch" sx={{ pl: '25px' }} {...props}>
    {children}
  </Grid>
)

export default TableGridContainer
