import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

const MainTitle = styled(Typography)`
  font-size: ${({ theme }) => theme.header.fontSize};
  font-weight: ${({ theme }) => theme.header.fontWeight};
`
type Props = {
  startComponent?: React.ReactElement
  middleComponent?: React.ReactElement
  endComponent?: React.ReactElement
  title?: string
  subTitle?: string
  menuComponent?: React.ReactElement
  subComponent?: React.ReactElement
}

const Header = ({
  startComponent,
  middleComponent,
  endComponent,
  title,
  subTitle,
  menuComponent,
  subComponent,
}: Props) => (
  <Grid container spacing={2} zIndex={100} position="relative">
    <Grid item xs={subTitle || subComponent ? 8 : 12}>
      <Stack direction="row" alignItems="center" spacing={2.5} sx={{ mb: 1 }}>
        {startComponent}
        <Stack>
          <MainTitle variant="h1" className="clamp-1" title={title}>
            {title}
          </MainTitle>
          {subTitle && <Typography variant="subtitle1">{subTitle}</Typography>}
          {subComponent}
        </Stack>
        {middleComponent}
      </Stack>
      {menuComponent && (
        <Stack direction="row" alignItems="center" spacing={2.5} sx={{ mb: 1 }}>
          {menuComponent}
        </Stack>
      )}
    </Grid>
    {endComponent && (
      <Grid item xs={4} sx={{ textAlign: 'right' }}>
        {endComponent}
      </Grid>
    )}
  </Grid>
)

export default Header
