import { Box, Button, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import moment from 'moment'
import { useEffect } from 'react'
import Link from 'next/link'
import ROUTES from '@constant/Routes'
import Header from '@module/Header/Header'
/* eslint-disable react/prop-types */
interface DebatesCountDownProps {
  publishedDate: string
  param: string
}

const DebatesCountDown: React.FunctionComponent<DebatesCountDownProps> = ({
  param,
  publishedDate,
}) => {
  const router = useRouter()
  return (
    <Box>
      <Box sx={{ ml: '5px' }}>
        <Header title="Debate Count-Down - Waiting to Launch" />
      </Box>
      <Button
        sx={{ ml: 1 }}
        variant="outlined"
        size="small"
        onClick={(e) => {
          e.stopPropagation()
          router.push(ROUTES.InternetAddDebate)
        }}
      >
        + Add Debate
      </Button>

      <Button
        sx={{ ml: 1 }}
        variant="outlined"
        size="small"
        onClick={(e) => {
          e.stopPropagation()
          router.push(ROUTES.InternetPriorDebates)
        }}
      >
        Prior Debates
      </Button>

      <Button
        sx={{ ml: 1 }}
        variant="outlined"
        size="small"
        onClick={(e) => {
          e.stopPropagation()
          router.push(ROUTES.InternetAddDebate)
        }}
      >
        + More ......
      </Button>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          marginTop: '200px',
        }}
      >
        <Box>
          <Typography variant="h4" fontWeight="bold" mb={3}>
            Waiting for launch
          </Typography>
        </Box>
        <Box>
          <Typography variant="h1" fontWeight="bold" mb={3} color="#606060">
            Debate Date
          </Typography>
        </Box>
        <Box>
          <Typography variant="h4" fontWeight="bold" mb={3}>
            Will Launch: {moment(publishedDate).format('llll')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" mb={3} color="#008db1">
            <Link href={ROUTES.InternetPriorDebates}>View Prior Debate days</Link>
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5" mb={3}>
            Opens in
          </Typography>
        </Box>
        <Box>
          <Typography variant="h2" fontWeight="bold" mb={3} color="#606060">
            {param}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default DebatesCountDown
