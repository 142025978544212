import { DeleteUpdateResponse, GetAllResponse, ListParams, QueryParams } from '@type/common'
import {
  CornerCountResponse,
  PhilosophicPack,
  PhilosophicPackCorner,
  PhilosophicPackUpdateDto,
  PhilosophicPackUserLogs,
} from '@type/philosophic-pack'
import { getAxios } from './api'

const API_URL = '/philosophic-pack'

export type PhilosophyPackListQueryParams = QueryParams & {
  areaId?: string
  exclude?: Array<string>
}

export const getAllPhilosophicPacks = async (
  params?: ListParams & { published?: boolean; isUGC?: boolean },
): Promise<{
  data: PhilosophicPack[]
  count: number
}> => {
  const { data } = await getAxios().get(API_URL, { params })
  return data
}

export const getPhilosophicPacksForMainProgress = async () => {
  const { data } = await getAxios().get<
    Pick<PhilosophicPack, 'id' | 'title'>[] | PhilosophicPack[]
  >(`${API_URL}/all`)
  return data
}

export const getPhilosophicPack = async (id: string): Promise<PhilosophicPack> => {
  const { data } = await getAxios().get<PhilosophicPack>(`${API_URL}/${id}`)
  return data
}

export const getPhilosophicPacksBySceneId = async (
  id: string,
): Promise<GetAllResponse<PhilosophicPack>> => {
  const { data } = await getAxios().get<GetAllResponse<PhilosophicPack>>(`${API_URL}/scene/${id}`)
  return data
}

export const fetchPhilosophicPackDataForGame = async (
  sceneId: string,
  majorAreaId: string,
): Promise<Array<PhilosophicPack>> => {
  const { data } = await getAxios().get<Array<PhilosophicPack>>(`${API_URL}/game/`, {
    params: { sceneId, majorAreaId },
  })
  return data
}

export const getPhilosophicPackUnlocked = async (sceneId: string, areaId: string) => {
  const { data } = await getAxios().get<PhilosophicPack>(
    `${API_URL}/scene/${sceneId}/area/${areaId}`,
    {
      params: { sceneId, areaId },
    },
  )
  return data
}

export const getPhilosophicPackGetailsById = async (philosophyPackId: string) => {
  const { data } = await getAxios().get<Array<PhilosophicPack>>(
    `${API_URL}/detail/${philosophyPackId}`,
  )
  return data
}

export const getAllPhilosophicPacksList = async (
  params: PhilosophyPackListQueryParams,
): Promise<GetAllResponse<PhilosophicPack>> => {
  const { data } = await getAxios().get<GetAllResponse<PhilosophicPack>>(`${API_URL}/detail`, {
    params,
  })
  return data
}

export const getPhilosophyPackByMajorIdAndCornerName = async (
  id: string,
  corner: PhilosophicPackCorner,
) => {
  const { data } = await getAxios().get<GetAllResponse<PhilosophicPack>>(
    `${API_URL}/list/scene-detail?undergroundId=${id}&corner=${corner}`,
  )
  return data
}

export const getPhilosophyPacksByRedBoxId = async (
  params: ListParams,
  redboxId: string,
): Promise<GetAllResponse<PhilosophicPack>> => {
  const { data } = await getAxios().get<GetAllResponse<PhilosophicPack>>(
    `${API_URL}/list/red-box/${redboxId}`,
    {
      params,
    },
  )
  return data
}

export type PhilosophyPackQueryParams = ListParams & {
  userId: string
}
export const getSolvedPhilosophyPacks = async (
  params: PhilosophyPackQueryParams,
): Promise<GetAllResponse<PhilosophicPack>> => {
  const { data } = await getAxios().get<GetAllResponse<PhilosophicPack>>(
    '/philosophy-pack-game-log',
    {
      params,
    },
  )
  return data
}

export const addPhilosophicPack = async (
  values: PhilosophicPackUpdateDto,
): Promise<PhilosophicPackUpdateDto> => {
  const formData = new FormData()
  const keys = Object.keys(values)
  for (let i = 0; i < keys.length; i += 1) {
    if (!(values[keys[i]] === null || values[keys[i]] === undefined)) {
      formData.append(keys[i], values[keys[i]])
    }
  }

  const { data } = await getAxios().post<PhilosophicPackUpdateDto>(`${API_URL}`, formData)
  return data
}

export const getCornerCounts = async (undergroundId: string): Promise<CornerCountResponse> => {
  const { data } = await getAxios().get<CornerCountResponse>(
    `${API_URL}/corner-counts/${undergroundId}`,
  )
  return data
}

export const editPhilosophicPack = async (
  values: PhilosophicPackUpdateDto,
): Promise<PhilosophicPack> => {
  const formData = new FormData()
  const keys = Object.keys(values)
  for (let i = 0; i < keys.length; i += 1) {
    if (!(values[keys[i]] === null || values[keys[i]] === undefined)) {
      formData.append(keys[i], values[keys[i]])
    }
  }
  const { data } = await getAxios().put<PhilosophicPack>(`${API_URL}/${values.id}`, formData)
  return data
}
export const editPhilosophicPackNonAdmin = async (
  values: PhilosophicPackUpdateDto,
): Promise<PhilosophicPack> => {
  const { data } = await getAxios().put<PhilosophicPack>(`${API_URL}/limited/${values.id}`, values)
  return data
}
export const updatePhilosophicPackGameLog = async (
  values: PhilosophicPackUserLogs,
): Promise<PhilosophicPack> => {
  const { data } = await getAxios().put<PhilosophicPack>('philosophy-pack-game-log', values)
  return data
}

export const deletePhilosophicPack = async (id: string): Promise<DeleteUpdateResponse> => {
  const { data } = await getAxios().delete<DeleteUpdateResponse>(`${API_URL}/${id}`)
  return data
}

export const publishTogglePhilosophyPack = async ({
  id,
  published,
}: {
  id: string
  published: boolean
}) => {
  const { data } = await getAxios().patch(`${API_URL}/publish/${id}`, {
    published,
  })
  return data
}
