export function openInNewTab(url) {
  const newTab = window.open(url, '_blank')
  if (newTab) {
    newTab.focus()
  } else {
    // Handle pop-up blocking or other issues
    // eslint-disable-next-line no-console
    console.error('Unable to open new tab. Please check browser settings.')
  }
}
