import { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useQuery } from 'react-query'
import Header from '@module/Header/Header'
import { getCurrentPublished } from '@api/debate.api'
import DebatesCountDown from './debates-count-down'
import DebatePublished from './debates-published'

const DebateNow = () => {
  // const [waitingTime, setWaitingTime] = useState('')
  const [published, setPublished] = useState(null)
  const [apiData, setDebateData] = useState<{ debate: any; param: string } | null>(null)

  const { data: publishedDebate } = useQuery(['publishedDebate'], () => getCurrentPublished(), {
    refetchOnMount: true,
  })

  useEffect(() => {
    let interval = null
    if (publishedDebate) {
      const countDownDate = new Date(publishedDebate.activityDay).getTime()
      interval = setInterval(() => {
        // Get today's date and time
        const now = new Date().getTime()

        // Find the distance between now and the count down date
        const distance = countDownDate - now

        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(distance / (1000 * 60 * 60 * 24))
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((distance % (1000 * 60)) / 1000)

        // Output the result in an element with id="demo"
        const remainingTime = `${days}d ${hours}h ${minutes}m ${seconds}s `
        setDebateData({
          debate: publishedDebate, // This condition could come from the API response
          param: remainingTime,
        })
        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(interval)
        }
        setPublished(distance)
      }, 1000)
    }
    return () => {
      clearInterval(interval)
    }
  }, [publishedDebate])
  /* eslint-disable consistent-return */
  const renderDebateNow = () => {
    if (publishedDebate === '') {
      return (
        <Box sx={{ ml: '5px' }}>
          <Header title="Debate Now" />
          <Box>
            <Typography variant="h6" mb={3} color="#008db1">
              Stay Tuned for the Upcoming Exciting Debate
            </Typography>
          </Box>
        </Box>
      )
    }

    if (!published || !apiData) {
      return (
        <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      )
    }

    if (published < 0) {
      return <DebatePublished param={apiData?.debate} />
    }

    return <DebatesCountDown param={apiData?.param} publishedDate={publishedDebate?.activityDay} />
  }

  return <> {renderDebateNow()} </>
}

export default DebateNow
