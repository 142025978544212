import { DeleteUpdateResponse, GetAllResponse, QueryParams } from '@type/common'
import { Forum } from '@type/forum'
import { CensorShip, PostForum, PostForumCreateDTO } from '@type/post-forum'
import { getAxios } from './api'

const forumPostApiPath = '/discussion-forum-posts'
export const addForumPostApi = async (forum: PostForumCreateDTO): Promise<Forum> => {
  const { data } = await getAxios().post(forumPostApiPath, forum)
  return data
}

export const getAllForumPostApi = async (params: QueryParams): Promise<GetAllResponse<Forum>> => {
  const { data } = await getAxios().get(forumPostApiPath, {
    params,
  })
  return data
}

export const getForumPostApi = async (id: string) => {
  const { data } = await getAxios().get(`${forumPostApiPath}/${id}`)
  return data
}

export const getForumPostByDebateIdApi = async (debateDayId: string) => {
  const { data } = await getAxios().get(`${forumPostApiPath}/find-by-debate/${debateDayId}`)
  return data
}

export const getFormattedForumPostByDebateId = async (params: QueryParams, debateDayId: string) => {
  const { data } = await getAxios().get<GetAllResponse<PostForum>>(
    `${forumPostApiPath}/find-by-debate/${debateDayId}`,
    {
      params,
    },
  )
  return data
}

export const deleteForumPostApi = async (id: string) => {
  const { data } = await getAxios().delete<DeleteUpdateResponse>(`${forumPostApiPath}/${id}`)
  return data
}

export const editForumPostApi = async (forumPost: PostForum) => {
  const { data } = await getAxios().put<DeleteUpdateResponse>(
    `${forumPostApiPath}/${forumPost.id}`,
    forumPost,
  )
  return data
}

export const updateForumCensorship = async (payload: CensorShip) => {
  const { data } = await getAxios().put<DeleteUpdateResponse>(
    `${forumPostApiPath}/censor/${payload.id}`,
    {
      isCensored: payload.isCensored,
    },
  )
  return data
}
